// core/assisting hamburger block
const HamburgerCore = function (context) {
    return {
        optionCurrent: '|CURRENT|', // selected currently active hamburger menu
        fieldHamburgerId: 'HAMBURGER_ID', // field id for selected hamburger menu

        // hamburger options
        options: [],

        // load hamburger options
        loadOptions: function() {
            this.options = context.hamburgers.map(function(hamburger) {
                return [hamburger.name, hamburger.id];
            });
            this.options.unshift(['Active hamburger menu', this.optionCurrent]);
        },

        // validate currently selected hamburger menu id
        changeHandler: function() {
            if (!!this.options && this.options.length > 0) {
                const hamburgerIdValue = this.getFieldValue(this.fieldHamburgerId);
                const option = this.options.find((o) => o[1] === hamburgerIdValue);
                if (!option) {
                    // selected hamburger was removed from app, set to default
                    this.setFieldValue(this.optionCurrent, this.fieldHamburgerId);
                }
            }
        },

        // get selected widget id, suitable for being used in Snap widgets API
        getSelectedWidgetId: function() {
            const hamburgerId = this.getFieldValue(this.fieldHamburgerId);
            return hamburgerId === this.optionCurrent ? 'null' : `"${hamburgerId}"`;
        }
    }
}

export default HamburgerCore;
