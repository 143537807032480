/* global Blockly */
import React from 'react';
import { hamburgerIcon } from "./index";
import HamburgerCore from "./HamburgerCore";

function defineBlock(context) {
    let blockInstance = HamburgerCore(context);
    Object.assign(blockInstance, {
        init: function () {
            this.loadOptions();
            this.appendDummyInput('WIDGET')
                .appendField('set')
                .appendField(new Blockly.FieldImage(hamburgerIcon, 30, 30, 'hamburger icon', null), 'hamburgerIcon')
                .appendField(new Blockly.FieldDropdown(this.options), this.fieldHamburgerId);

            this.appendValueInput('VALUE')
                .appendField('hamburger menu name to')
                .setCheck("String");

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the name of a hamburger menu.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
            this.setOnChange(this.changeHandler);
        }
    });
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            const hamburgerId = block.getSelectedWidgetId();
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            var code = `Snap.hamburgers.setHamburgerTitle(${hamburgerId}, ${value});\n`;
            return code;
        }
    }
}

const SetHamburgerName = function (props) {
    const name = 'snap_set_hamburger_name';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.context.hamburgers.length ? 
        <block type={name}>
            <value name="VALUE">
                <shadow type="text"></shadow>
            </value>
        </block> : 
        null;
}

export default SetHamburgerName
