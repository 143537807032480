/* global Blockly */
import React from 'react';
import { hamburgerIcon } from "./index";
import HamburgerCore from "./HamburgerCore";

function defineBlock(context) {
    let blockInstance = HamburgerCore(context);
    Object.assign(blockInstance, {
        init: function () {
            this.loadOptions();
            this.appendDummyInput('WIDGET')
                .appendField("get")
                .appendField(new Blockly.FieldImage(hamburgerIcon, 30, 30, "hamburger icon", null), 'hamburgerIcon')
                .appendField(new Blockly.FieldDropdown(this.options), this.fieldHamburgerId)
                .appendField("hamburger menu image");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the image URL of a hamburger menu.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
            this.setOnChange(this.changeHandler);
        }
    });
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            const hamburgerId = block.getSelectedWidgetId();
            var code = `Snap.hamburgers.getHamburgerImage(${hamburgerId})`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetHamburgerImage = function (props) {
    const name = 'snap_get_hamburger_image';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.context.hamburgers.length ? <block type={name}></block> : null;
}

export default GetHamburgerImage
