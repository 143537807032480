/* global Blockly */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { doDomNodesIntersect } from "../../utils";
import './index.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ActionButtons = ({ saveMyBlock, modalOpen, modalChecked, modalPopup, modalContent }) => {
    // undo button
    const [hoveredUndo, setHoveredUndo] = useState(false);
    const toggleHoverUndo = () => setHoveredUndo(!hoveredUndo);
    const [undoEnabled, setUndoEnabled] = useState(false);

    // redo button
    const [hoveredRedo, setHoveredRedo] = useState(false);
    const toggleHoverRedo = () => setHoveredRedo(!hoveredRedo);
    const [redoEnabled, setRedoEnabled] = useState(false);
    
    // save button
    const saveRef = useRef(null);
    const [isSaveHovered, setIsSaveHovered] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    // comment button
    const [hoveredComment, setHoveredComment] = useState(false);
    const enableHoverComment = () => setHoveredComment(true);
    const disableHoverComment = () => setHoveredComment(false);

    // modal popup button
    const [hoveredModal, setHoveredModal] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const enableHoverModal = () => setHoveredModal(true);
    const disableHoverModal = () => setHoveredModal(false);
    useEffect(() => {
        if (modalContent != '' && modalContent != '<p><br></p>') {
            setHasContent(true);
        } else {
            setHasContent(false);
        }
    }, [modalContent]);

    // reset zoom button
    const [hoveredResetZoom, setHoveredResetZoom] = useState(false);
    const toggleHoverResetZoom = () => setHoveredResetZoom(!hoveredResetZoom);

    // zoom in button
    const [hoveredZoomIn, setHoveredZoomIn] = useState(false);
    const toggleHoverZoomIn = () => setHoveredZoomIn(!hoveredZoomIn);
    const [zoomInEnabled, setZoomInEnabled] = useState(true);

    // zoom out button
    const [hoveredZoomOut, setHoveredZoomOut] = useState(false);
    const toggleHoverZoomOut = () => setHoveredZoomOut(!hoveredZoomOut);
    const [zoomOutEnabled, setZoomOutEnabled] = useState(true);

    // help button
    const [hoveredHelp, setHoveredHelp] = useState(false);
    const toggleHoverHelp = () => setHoveredHelp(!hoveredHelp);

    const tooltip = (
        <Tooltip id="tooltip">Click to toggle comments</Tooltip>
      );
    
    const Popuptooltip = (
        <Tooltip id="Popuptooltip">Click to toggle Popup Modal</Tooltip>
    );

    /**
     * Function to handle comment visibility. 
     * If any comments are visible, clicking the respective button will close them all
     * if all comments are closed then clicking the button will make all comments visible again
    */
    const handleCommentBubbles = () => {
        Blockly.JavaScript.init(Blockly.mainWorkspace);
        var commentVisible = false;
        Object.entries(Blockly.mainWorkspace.blockDB_).forEach((item) => {
            if (item[1].comment) {
                if (item[1].comment.isVisible()) {
                    commentVisible = true;
                }
            }
        });
        Object.entries(Blockly.mainWorkspace.commentDB_).forEach((item) => {
            if (item[1]) {
                if (!item[1].isMinimized_) {
                    commentVisible = true;
                }
            }
        });

        Object.entries(Blockly.mainWorkspace.blockDB_).forEach((item) => {
            if (item[1].comment) {
                item[1].comment.setVisible(!commentVisible);
            }
        });

        Object.entries(Blockly.mainWorkspace.commentDB_).forEach((item) => {
            if (item[1]) {
                item[1].setMinimized(commentVisible);
            }
        });
    }

    useEffect(() => {
        // when a block is dragged, check if the block overlaps with the save button
        var currentlyDragging = Blockly.BlockDragSurfaceSvg.prototype.translateSurface;

        Blockly.BlockDragSurfaceSvg.prototype.translateSurface = function () {
            setSaveEnabled(true);
            // if the block is dragged onto save button, highlight it
            if (Blockly.selected && doDomNodesIntersect(Blockly.selected.getSvgRoot(), saveRef.current)) {
                setIsSaveHovered(1);
            } else { // if the boxes no longer intersect, change the save icon back to normal
                setIsSaveHovered(0);
            }
            currentlyDragging.call(this, ...arguments);
        };
    }, [saveRef]);

    useEffect(() => {
        setTimeout(() => {
            Blockly.mainWorkspace.addChangeListener(blocklyEvent => {
                switch (blocklyEvent.type) {
                    case Blockly.Events.BLOCK_MOVE:
                        // get the current block we are moving (in both Blockly and HTML) and the garbage can

                        // the BLOCK_MOVE event is fired:
                        // - once when a top-level block is placed back on the workspace
                        // - once when a block connected to a previous block is selected (or picked up)
                        // - once when a block nested inside another block is picked up
                        // - twice when a block is connected to a previous block
                        // - twice when a block is placed (or nested) inside another block
                        // - twice when a block nested inside a block that has a previous block is picked up
                        // - three times when a block is placed inside another block that is connected to a
                        // previous block


                        // when the mouse is released after dragging a block, check if the selected block overlaps
                        // the garbage can or the save icon

                        // if the selected block overlaps the save icon, save it
                        if (Blockly.selected && doDomNodesIntersect(Blockly.selected.getSvgRoot(), saveRef.current)) {
                            saveMyBlock(Blockly.selected);
                            setIsSaveHovered(0);
                            Blockly.selected.moveBy(-600, -100);

                            // restore opacity after running the save prompt
                            let selected = document.querySelector(".save-hovered");
                            if (selected) { 
                                selected.style.opacity = 1;
                            }
                        }
                        break;

                    case Blockly.Events.END_DRAG:
                        setTimeout(function() {
                            setSaveEnabled(false);
                        }, 1);
                        break;

                    case "zoomChanged": 
                        // enable disable zoom buttons
                        setZoomInEnabled(!!Blockly.mainWorkspace && Blockly.mainWorkspace.options.zoomOptions.maxScale > Blockly.mainWorkspace.scale);
                        setZoomOutEnabled(!!Blockly.mainWorkspace && Blockly.mainWorkspace.options.zoomOptions.minScale < Blockly.mainWorkspace.scale);
                        break;
                }

                // enable/disable action buttons
                setUndoEnabled(!!Blockly.mainWorkspace && Blockly.mainWorkspace.undoStack_.length > 0);
                setRedoEnabled(!!Blockly.mainWorkspace && Blockly.mainWorkspace.redoStack_.length > 0);
            });
        }, 0)
    }, [Blockly.mainWorkspace])

    useEffect(() => {
        let selected = document.querySelector(".blocklySelected");
        if (!selected) { return; }

        if (isSaveHovered) {
            selected.classList.add("save-hovered");
            selected.style.opacity = 0.5;
        } else {
            selected.classList.remove("save-hovered");
            selected.style.opacity = 1;
        }
    }, [isSaveHovered]);

    const handleResetZoom = (e) => {
        var workspace = Blockly.mainWorkspace;
        workspace.markFocused();
        workspace.setScale(workspace.options.zoomOptions.startScale);
        workspace.scrollCenter();
        Blockly.Touch.clearTouchIdentifier();  // Don't block future drags.
        e.stopPropagation();  // Don't start a workspace scroll.
        e.preventDefault();  // Stop double-clicking from selecting text.
    }

    const handleZoomIn = (e) => {
        var workspace = Blockly.mainWorkspace;
        workspace.markFocused();
        workspace.zoomCenter(1);
        Blockly.Touch.clearTouchIdentifier();  // Don't block future drags.
        e.stopPropagation();  // Don't start a workspace scroll.
        e.preventDefault();  // Stop double-clicking from selecting text.
    }

    const handleZoomOut = (e) => {
        var workspace = Blockly.mainWorkspace;
        workspace.markFocused();
        workspace.zoomCenter(-1);
        Blockly.Touch.clearTouchIdentifier();  // Don't block future drags.
        e.stopPropagation();  // Don't start a workspace scroll.
        e.preventDefault();  // Stop double-clicking from selecting text.
    }

    const handleHelp = (e) => {
        window.open('/docs', '_blank');
        e.stopPropagation();  // Don't start a workspace scroll.
        e.preventDefault();  // Stop double-clicking from selecting text.
    }

    return (
        <div className={"action-buttons-container"}>
            <span className={classNames("icon with-icon-paths", 
                { "snap-icon-undo": !hoveredUndo || !undoEnabled }, { "snap-icon-undo_dual": hoveredUndo && undoEnabled }, { 'disabled': !undoEnabled })} 
                onMouseEnter={toggleHoverUndo} onMouseLeave={toggleHoverUndo}
                onClick={()=>{Blockly.mainWorkspace.undo(false)}} />
            <span className={classNames("icon with-icon-paths", 
                { "snap-icon-redo": !hoveredRedo || !redoEnabled }, { "snap-icon-redo_dual": hoveredRedo && redoEnabled }, { 'disabled': !redoEnabled })} 
                onMouseEnter={toggleHoverRedo} onMouseLeave={toggleHoverRedo}
                onClick={()=>{Blockly.mainWorkspace.undo(true)}} />
            <span className={classNames("icon with-icon-paths", 
                { "snap-icon-save": !isSaveHovered || !saveEnabled }, { "snap-icon-save_dual": isSaveHovered && saveEnabled }, { 'disabled': !saveEnabled })} 
                ref={saveRef} />
            <OverlayTrigger placement="left" overlay={tooltip} onEntered={enableHoverComment} onExited={disableHoverComment}>
                <span className={classNames("icon with-icon-paths", { "snap-icon-SMS": !hoveredComment }, { "snap-icon-SMS_dual": hoveredComment })} 
                    onClick={handleCommentBubbles} />
            </OverlayTrigger>
            <OverlayTrigger placement="left" overlay={Popuptooltip} onEntered={enableHoverModal} onExited={disableHoverModal}>
            <span className={classNames("icon with-icon-paths", { "snap-icon-modal_popup": !hoveredModal && !hasContent }, { "snap-icon-modal_popup_blue": hasContent && !hoveredModal }, { "snap-icon-modal_popup_dual": hoveredModal })} 
                    onClick={() => {modalPopup(!modalOpen, modalChecked)}}/>
            </OverlayTrigger>
            <span className={'action-buttons-separator'} />
            <span className={classNames("icon with-icon-paths", { "snap-icon-target": !hoveredResetZoom }, { "snap-icon-target_dual": hoveredResetZoom })} 
                onMouseEnter={toggleHoverResetZoom} onMouseLeave={toggleHoverResetZoom}
                onClick={handleResetZoom} />
            <div className='snap-zoom'>
                <span className={classNames("icon with-icon-paths", 
                    { "snap-icon-zoom_in": !hoveredZoomIn || !zoomInEnabled }, { "snap-icon-zoom_in_dual": hoveredZoomIn && zoomInEnabled }, { 'disabled': !zoomInEnabled })} 
                    onMouseEnter={toggleHoverZoomIn} onMouseLeave={toggleHoverZoomIn}
                    onClick={handleZoomIn} />
                <span className={classNames("icon with-icon-paths", 
                    { "snap-icon-Zoom_out": !hoveredZoomOut || !zoomOutEnabled }, { "snap-icon-Zoom_out_dual": hoveredZoomOut && zoomOutEnabled }, { 'disabled': !zoomOutEnabled })} 
                    onMouseEnter={toggleHoverZoomOut} onMouseLeave={toggleHoverZoomOut}
                    onClick={handleZoomOut} />
            </div>
            <span className={classNames("icon with-icon-paths", { "snap-icon-help": !hoveredHelp }, { "snap-icon-help_dual": hoveredHelp })} 
                onMouseEnter={toggleHoverHelp} onMouseLeave={toggleHoverHelp}
                onClick={handleHelp} />
        </div>
    );
};

export default ActionButtons;